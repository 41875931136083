$("#font-size").on("click", function () {
  wcag("font-size");
});

$("#contrast").on("click", function () {
  wcag("contrast");
});

wcagCheck("font-size");
wcagCheck("contrast");

function wcag(name) {
  if (!$("html").hasClass(name)) {
    $("html").addClass(name);
    $("#" + name).addClass("active");
    localStorage.setItem(name, "true");
  } else {
    $("html").removeClass(name);
    $("#" + name).removeClass("active");
    localStorage.setItem(name, "false");
  }
}

function wcagCheck(name) {
  if (localStorage.getItem(name) == "true") {
    $("html").addClass(name);
    $("#" + name).addClass("active");
  } else {
    $("html").removeClass(name);
    $("#" + name).removeClass("active");
  }
}
