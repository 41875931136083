$(window).on("scroll", function () {
  sticky();
});

let offset = 0;
if ($(window).outerWidth(true) < 420) {
  offset = 147;
} else if (
  $(window).outerWidth(true) >= 420 &&
  $(window).outerWidth(true) < 576
) {
  offset = 152;
} else if (
  $(window).outerWidth(true) >= 576 &&
  $(window).outerWidth(true) < 768
) {
  offset = 162;
} else {
  offset = 215;
}

$(window).resize(function () {
  sticky();
  if ($(window).outerWidth(true) > 991) {
    $(".header-bottom nav").css({ display: "block", height: "auto" });
  } else {
    $(".header-bottom nav").css({ display: "none" });
  }
});

$(".hamburger").on("click", function () {
  if (!$(this).hasClass("hamburger--active")) {
    $(this).addClass("hamburger--active");

    if (!$(".header-bottom").hasClass("sticky")) {
      $(".header-bottom").addClass("sticky");
    }
    $(".header-bottom nav").css({
      height: $(window).height() - ($(window).outerWidth(true) > 575 ? 80 : 64),
    });
    $(".header-bottom nav").slideDown({
      start: function () {
        $(this).css({
          display: "flex",
        });
      },
    });
  } else {
    $(this).removeClass("hamburger--active");
    sticky();
    $(".header-bottom nav").fadeOut(200);
  }
});

function sticky() {
  if ($(window).scrollTop() > offset) {
    $(".header-bottom").addClass("sticky");
  } else if ($(window).scrollTop() <= offset) {
    $(".header-bottom").removeClass("sticky");
  }

  if ($(".hamburger").hasClass("hamburger--active")) {
    $(".header").addClass("sticky");
  }
}
