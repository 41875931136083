import { tns } from "tiny-slider/src/tiny-slider";

if ($(".hero-slider").length > 0) {
  let heroSlider = tns({
    container: ".hero-slider",
    speed: 2000,
    lazyload: true,
    items: 1,
    autoplay: true,
    autoplayButtonOutput: true,
    controls: false,
    navPosition: "bottom",
    autoplayText: ["▶", "❚❚"],
  });
}
