if ($(".tabs__nav").length > 0) {
  activePosition(1);
}

$(".tabs__nav button").on("click", function () {
  
    if (!$(this).hasClass("active")) {
      $(".tabs__nav button").removeClass("active");
      $(".tabs__content").removeClass("active");
      $(this).addClass("active");
      const id = $(this).data("id");
      $(`.tabs__content[data-id="${id}"]`).addClass("active");
      if($(window).outerWidth(true) > 575) {
        activePosition(id);
      }
    }
  
});

function activePosition(id) {
  const left = $(window).outerWidth(true) < 992 ? 5 : 20;
  $(".tabs__nav span").css({
    left: $(`.tabs__nav button[data-id="${id}"]`).position().left + left,
    width: $(`.tabs__nav button[data-id="${id}"]`).outerWidth(),
  });
}
