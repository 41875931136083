import { tns } from "tiny-slider/src/tiny-slider";
import lightbox from "lightbox2";

lightbox.option({
  showImageNumberLabel: false,
});

if ($(".article-slider").length > 0) {
  for (let i = 0; i < $(".article-slider").length; i++) {
    sliderInit(i);
  }
}

function sliderInit(i) {
  const id = $(".article-slider").eq(i).data("id");

  let articleSlider = tns({
    container: ".article-slider .article-slider__wrapper[data-id='" + id + "']",
    loop: true,
    speed: 2000,
    controls: false,
    navPosition: "bottom",
    lazyload: true,
    autoWidth: true,
    autoplay: true,
    autoplayButtonOutput: false,
    responsive: {
      0: {
        items: 2,
        gutter: 5,
      },
      768: {
        gutter: 10,
        items: 3,
        center: true,
      },
    },
  });
}
