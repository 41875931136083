import { stripHtml, cropText } from "../tools/tools";
import {
  pagination,
  activePaginationNumber,
  paginationLength,
  paginationScroll,
} from "../tools/pagination";
import { loader } from "../tools/loader";

const moment = require("moment");
moment.locale("pl");

let xhr = null;

displayPosts(1);

$("#news").on("click", ".pagination__number", function () {
  if (!$(this).hasClass("active")) {
    $("#news-wrapper").prepend(loader());
    $(".article-box").css("filter", "blur(1px)");
    paginationScroll();
    $(this).addClass("active");
    const page = $(this).data("id");
    displayPosts(page);
  }
});

$("#news").on("click", ".pagination__prev", function () {
  const an = activePaginationNumber();
  if (an > 1) {
    const page = an - 1;
    $("#news-wrapper").prepend(loader());
    $(".article-box").css("filter", "blur(1px)");
    paginationScroll();
    displayPosts(page);
  }
});

$("#news").on("click", ".pagination__next", function () {
  const an = activePaginationNumber();
  const pl = paginationLength();
  if (an < pl) {
    const page = an + 1;
    $("#news-wrapper").prepend(loader());
    $(".article-box").css("filter", "blur(1px)");
    paginationScroll();
    displayPosts(page);
  }
});

function displayPosts(page) {
  let params = {};
  params._embed = true;
  params.page = page || 1;
  params.per_page = 5;
  if (xhr) {
    xhr.abort();
  }
  xhr = $.get(
    "/wp-json/wp/v2/posts",
    params,
    function (response, textStatus, jqXHR) {
      xhr = null;
      let totalPages = jqXHR.getResponseHeader("X-WP-TotalPages");
      let posts = "";
      if (response.length > 0) {
        response.forEach((post) => {
          posts += createPost(post);
        });
        $("#news-wrapper").html(posts);
        totalPages > 1
          ? $("#pagination-wrapper").html(pagination(totalPages, page))
          : "";
      } else {
        $("#news-wrapper").html("<p>Brak wpisów</p>");
        $("#pagination-wrapper").html("");
      }
    },
    "json"
  );
}

function createPost(post) {
  const image =
    post._embedded["wp:featuredmedia"] != undefined
      ? post._embedded["wp:featuredmedia"][0].source_url
      : "";

  let description = "";
  post.acf.flexible_content.forEach((content) => {
    if (content.text !== undefined) {
      description += content.text;
    }
  });

  return `
    <div class="col-12 offset-xl-1 col-xl-10">
      <article
        class="article-box row mx-0"
        data-aos="fade-up"
        data-aos-duration="1200"
        data-aos-delay="0"
      >
        <div
          class="article-box__img col-12 col-md-4"
          style="background-image: url(${image})"
        ></div>
        <div
          class="article-box__wrapper article-box__wrapper--center col-12 col-md-8"
        >
          <span>${moment(post.date).format("LL")}</span>
          <h5>${post.title.rendered}</h5>
          <p>${post.excerpt.rendered}</p>
        </div>
        <a href="${post.link}" class="article-box__link"></a>
      </article>
    </div>
  `;
}
