let status = true;

if ($(".progress-circle").length > 0) {
  progressCircleInit();
}

function progressCircleInit() {
  $(window).on("scroll", function () {
    if (
      $(window).scrollTop() > $(".progress-circle").offset().top - 800 &&
      status
    ) {
      progressCircle();
      status = false;
    }
  });
}

function progressCircle() {
  const value = $(".progress-circle").data("value");
  let radius = 0;
  if (
    $(window).outerWidth(true) < 576 ||
    ($(window).outerWidth(true) < 1200 && $(window).outerWidth(true) >= 768)
  ) {
    radius = 110;
  } else {
    radius = 180;
  }
  let circumference = radius * 2 * Math.PI;
  $(".progress").eq(0).css({ "stroke-dasharray": circumference });
  displayProgress(value, circumference);
}

function displayProgress(percent, circumference) {
  $(".progress-circle").append(`<h2></h2>`);

  let start = 0;
  let stop = percent;
  let duration = 1200;
  let interval = 50;
  let step = (stop - start) / (duration / interval);
  let i = parseInt(start);

  let counInterval = setInterval(function () {
    if (i < stop) {
      i += Math.ceil(step);
    } else {
      i = stop;
      clearInterval(counInterval);
    }
    $(".progress-circle h2").html(i + "%");
    $(".progress")
      .eq(0)
      .css({
        "stroke-dashoffset": circumference - (i / 100) * circumference,
      });
  }, interval);
}
