export function activePaginationNumber() {
  let activePaginationNumber = null;
  $(".pagination__number").each(function () {
    if ($(this).hasClass("active")) {
      activePaginationNumber = $(this).data("id");
    }
  });
  return activePaginationNumber;
}

export function paginationLength() {
  let paginationLength = $(".pagination__number").length;
  return paginationLength;
}

export function paginationScroll() {
  $("html, body").animate(
    {
      scrollTop: $("#news-wrapper").offset().top - 260,
    },
    10
  );
}

export function pagination(totalEntries, activePage = 1) {
  let li = "";
  for (let i = 1; i <= totalEntries; i++) {
    li += `
    <li>
      <button 
        data-id="${i}" 
        class="pagination__number ${i == activePage ? "active" : ""}
      ">
        ${i}
      </button>
    </li>`;
  }

  return `
  <div 
    class="pagination"
    data-aos="fade-up"
    data-aos-duration="1200"
    data-aos-delay="100"
  >
    <button class="pagination__prev">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="37.583"
        height="37.583"
        viewBox="0 0 37.583 37.583"
      >
        <path
          d="M40.958,22.167A18.792,18.792,0,1,1,22.167,3.375,18.789,18.789,0,0,1,40.958,22.167Zm-22.089,0,7.4-7.327A1.744,1.744,0,0,0,23.8,12.373l-8.619,8.646a1.742,1.742,0,0,0-.054,2.4l8.492,8.519a1.741,1.741,0,1,0,2.466-2.457Z"
          transform="translate(-3.375 -3.375)"
        />
      </svg>
    </button>
    <ul>${li}</ul>
    <button class="pagination__next">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="37.583"
        height="37.583"
        viewBox="0 0 37.583 37.583"
      >
        <path
          d="M3.375,22.167A18.792,18.792,0,1,0,22.167,3.375,18.789,18.789,0,0,0,3.375,22.167Zm22.089,0-7.4-7.327a1.744,1.744,0,0,1,2.466-2.466l8.619,8.646a1.742,1.742,0,0,1,.054,2.4l-8.492,8.519a1.741,1.741,0,1,1-2.466-2.457Z"
          transform="translate(-3.375 -3.375)"
        />
      </svg>
    </button>
  </div>
  `;
}
